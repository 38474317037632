import React from "react"
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: nie znaleziono" />
		<Banner title="404" />
		<p className="not-found__text h3">
			Podana strona nie istnieje wróc do <Link className="text__link h3" to="/">Strony głównej</Link>
		</p>
	</Layout>
)

export default NotFoundPage
